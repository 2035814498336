/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "palsie/pals5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "palsie/pals5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(relativePath: { eq: "palsie/plint.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `M.Pals - plafond plint`,
  textWithLink: () => (
    <>
      <p>
        Een platte plafondplint (ook wel een Joden plint genoemd) geeft net even
        dat beetje extra aan een plafond in uw woonkamer of zelfs aan uw
        badkamer. Een plint van gips (dus niet van piepschuim) die strak langs
        de randen van het gehele plafond worden geplaatst worden door de
        stukadoor met de hand aangebracht.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0629575549'>
          <Button
            role='button'
            variant='contained'
            className='btn heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
    </>
  ),
};

const textBottom = {
  textWithLink: () => (
    <>
      <AniLink fade to='/wandafwerking'>
        <Button
          role='button'
          variant='contained'
          className='btn heading__button'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const plafondPlint = ({ data, path, location }) => {
  const seo = {
    title: `Plafond plint`,
    description: `Geef je huis net een beetje extra design met een prachtige plafond plint.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'M.Pals Stukadoors',
    imgTitle: 'M.Pals Stukadoors',
    header: 'M.Pals - Plafond plint',
    textWithLink: () => (
      <>
        <p>
          Door het aanbrengen van een RVS profiel 12 tot 15 cm vanaf de wanden
          krijgt u een mooi opstaand randje van 6 mm dikte. Wilt u de opstaande
          rand van de plint hoger hebben dan is dit uiteraard geen probleem en
          kan de stukadoor hiervoor een andere profiel maat kiezen.
        </p>
      </>
    ),
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Plafond plint`,
    pitchLine: `Geef je huis net een beetje extra design met een prachtige plafond plint.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        textBottom={textBottom}
      />
    </>
  );
};

plafondPlint.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default plafondPlint;
